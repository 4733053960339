import React from "react"
import { BaseSyntheticEvent } from "react"
import { Link } from "react-router-dom"
//import { Banner, Grid, GridItem, Icon } from "src/components"
import { Banner, Icon } from "src/components"
import { useAppSelector } from "src/hooks/redux"

import {
  selectIsPermanentCardClosed,
  selectFinancialAccount,
  selectPhysicalCardOrder,
  selectShouldActivatePermanentCard,
  selectShouldOrderPermanentCard,
  selectTransactions,
  selectShouldTrackPhysicalCardOrder,
  selectCanHaveTempCard,
  selectTemporaryPaymentCard,
  selectIsTempCardClosed,
  selectAreFinancialAccountTransactionsLoading
} from "src/store"
import { CARD_VIEW_PAGE_ROUTE } from "src/utilities/routingUtils"
//import AvailableBalance from "./AvailableBalance"
import TransactionsViewHeader from "./TransactionsViewHeader"
//import BankingDetails from "./BankingDetails"
import OrderPhysicalCard from "../PaymentCard/OrderPhysicalCard"
import { CardShipmentTracker } from "./PhysicalCardOrder/CardShipmentTracker"
import Transactions from "./Transactions"
import OrderTemporaryCard from "../PaymentCard/OrderTemporaryCard"
import {useEffect,useState} from "react"
import { getAccountHolderStatusInfo } from "src/api"
import { Center } from "@mantine/core"
//import { Center } from "@mantine/core"

const FullyOnboardedV2: React.VFC = () => {
  const financialAccount = useAppSelector(selectFinancialAccount)
  //const financialAccountLoadingStatus = useAppSelector(selectFinancialAccountDetailsLoading)
  const transactions = useAppSelector(selectTransactions)
  const shouldActivatePermanentCard = useAppSelector(
    selectShouldActivatePermanentCard,
  )
  const shouldOrderPermanentCard = useAppSelector(
    selectShouldOrderPermanentCard,
  )
  const shouldTrackPermanentCard = useAppSelector(
    selectShouldTrackPhysicalCardOrder,
  )
  const cardOrder = useAppSelector(selectPhysicalCardOrder)
  const arePaymentCardsClosed = useAppSelector(selectIsPermanentCardClosed)

  const tempCard = useAppSelector(selectTemporaryPaymentCard)
  const canHaveTempCard = useAppSelector(selectCanHaveTempCard)
  const isTempCardClosed = useAppSelector(selectIsTempCardClosed)

  /*
    These state variables are simply for filtering content and are not the SOR for the state variable changes
    The SOR for filter updates exist in TrasactionsFilterView
  */
  const [isTodayFilterActive, setTodayFilterActive] = useState(true);
  const [isOneWeekFilterActive, setOneWeekFilterActive] = useState(false);
  const [isTwoWeeksFilterActive, setTwoWeeksFilterActive] = useState(false);
  const [isNoFilterActive, setNoFilterActive] = useState(false);

  const [isInitialized, setInitialized] = useState(false);
  //const [permanentCardStatus, setPermanentCardStatus] = useState('UNKNOWN');
  //const [temporaryCardStatus, setTemporaryCardStatus] = useState('UNKNOWN')

  const [searchBarText, setSearchBarText] = useState('');
  //const [transactionsLoaded, setTransactionsLoaded] = useState(false);

  const isTransactionsLoading = useAppSelector(selectAreFinancialAccountTransactionsLoading)


  //initialization to determine when to display the transaction experience

  useEffect(() => {

    console.log("Calling Use Effect before getAccountHolderStatuses")
    console.log(isTransactionsLoading)
    getAccountHolderStatuses();
  
  })

  /*
  useEffect(() => {
    const interval = setInterval(() => {
      if(!transactionsLoaded){
        if(financialAccount?.transactionsLoaded){
          console.log("Transactions loaded")
          setTransactionsLoaded(true);
        }
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);
  */

  /*
  useEffect(() => {

    console.log("Running Transaction Loading Use Effect")
    if(!transactionsLoaded){
      if(financialAccount?.transactionsLoaded){
        console.log("Transactions loaded")
        setTransactionsLoaded(true);
      }
    }
    
  },[transactionsLoaded])
  */

  /*
  useEffect(() => {

    if(!transactionsLoaded){
      if(financialAccount?.transactionsLoaded){
        console.log("Transactions loaded")
        setTransactionsLoaded(true);
      }
    }
    
  })
  */

  function getAccountHolderStatuses(){

    if(!isInitialized){

      getAccountHolderStatusInfo().then(res => {
  
        if(res?.success == true){
  
          setInitialized(true);
   
        }
    
      })

    }

  }
  
  /*
    Callback functions to faciliate the transaction experience
  */

  /*
  const determineIfTransactionsLoaded = () => {
    if(financialAccount){
      if(financialAccount?.transactionsLoaded){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  */

  //transaction filter callbacks
  const todayFilterClickedCallback = () => {
    setTodayFilterActive(true);
    setOneWeekFilterActive(false);
    setTwoWeeksFilterActive(false);
    setNoFilterActive(false);

  }

  const oneWeekFilterClickedCallback = () => {
    setOneWeekFilterActive(true);
    setTodayFilterActive(false);
    setTwoWeeksFilterActive(false);
    setNoFilterActive(false);
  }

  const twoWeekFilterClickedCallback = () => {
    setTwoWeeksFilterActive(true);
    setOneWeekFilterActive(false);
    setTodayFilterActive(false);
    setNoFilterActive(false);
  }

  const noFilterClickedCallback = () => {
    setTwoWeeksFilterActive(false);
    setOneWeekFilterActive(false);
    setTodayFilterActive(false);
    setNoFilterActive(true);
  }

  const searchBarClickedCallback = (evt:BaseSyntheticEvent) => {

    let searchText = '';

    if(evt.target.value != undefined){
      searchText = evt.target.value;
    }
    //console.log(searchText)
    setSearchBarText(searchText)
  }

  const clearSearchBarClickedCallback = () =>{
    setSearchBarText('');
  }

  return (
    <>
      {shouldActivatePermanentCard && (
        <Banner as={Link} to={CARD_VIEW_PAGE_ROUTE}>
          <Icon id="info" />
          Received your new Stretch Mastercard® debit card? Activate it now and
          start using it for in-person or online&nbsp;purchases.
        </Banner>
      )}
      {arePaymentCardsClosed && (
        <Banner as={Link} to={CARD_VIEW_PAGE_ROUTE}>
          <Icon id="info" />
          Your Stretch Mastercard® debit card is closed. Click here to re-order
          a new card.
        </Banner>
      )}
      {shouldOrderPermanentCard && (
        <>
          <OrderPhysicalCard />
          <div className="mt-10 wideOnly"></div>
          <div className="mt-6 narrowOnly"></div>
        </>
      )}
      {(!tempCard || isTempCardClosed) && canHaveTempCard && (
        <>
          <OrderTemporaryCard />
          <div className="mt-10 wideOnly"></div>
          <div className="mt-6 narrowOnly"></div>
        </>
      )}
      {shouldTrackPermanentCard && cardOrder && (
        <>
          <CardShipmentTracker cardOrder={cardOrder} />
          <div className="mt-10 wideOnly"></div>
          <div className="mt-6 narrowOnly"></div>
        </>
      )}
    
      
        <>
           <Center>
            
            <TransactionsViewHeader financialAccount={financialAccount} 
            todayFilterClickedCallback={todayFilterClickedCallback} 
            oneWeekFilterClickedCallback={oneWeekFilterClickedCallback} 
            twoWeekFilterClickedCallback={twoWeekFilterClickedCallback}
            noFilterClickedCallback={noFilterClickedCallback}
            searchBarClickedCallback={searchBarClickedCallback}
            clearSearchBarClickedCallback={clearSearchBarClickedCallback}
            /> 

            </Center>
          
        </>
      
      
      <div className="mt-10 wideOnly"></div>
      <div className="mt-6 narrowOnly"></div>

      
        <>
        <div style={{margin: 'auto',
        width:'100%',
        padding: '0px',
        minWidth: '200px',
        maxWidth: '675px'}}>

        

        <Transactions 
          transactions = {transactions} 
          leftFilterIsActive = {isTodayFilterActive} 
          middleFilterIsActive = {isOneWeekFilterActive}
          rightFilterIsActive = {isTwoWeeksFilterActive}
          noFilterIsActive = {isNoFilterActive}
          searchText={searchBarText}
          transactionsLoaded={isTransactionsLoading}
        />
        </div>
        </>
      
    </>
  )
}

/*

<Grid>
        <GridItem $span={4} $spanS={12} $equalHeight>
            <TransactionsViewHeader financialAccount={financialAccount} />
        </GridItem> 
      </Grid>
*/


/*

<GridItem $span={4} $spanS={12} $equalHeight>
          <Card $slimPadding>
            <AvailableBalance financialAccount={financialAccount} />
          </Card>
        </GridItem>
        <GridItem $span={8} $spanS={12} $equalHeight>
          <Card $slimPadding>
            <BankingDetails financialAccount={financialAccount} />
          </Card>
        </GridItem>
*/

export default FullyOnboardedV2
