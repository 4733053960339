import { Form, FormikHelpers } from "formik"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import * as yup from "yup"
import NumericInput from "src/components/shared/inputs/NumericInput"
import {
  Button,
  Pages,
  Feedback,
  Headings,
  Panels,
  StretchForm,
  SubmitButton,
  Spacing,
} from "src/components"
import { useOnboardingContext } from "src/context/OnboardingContext"
import { useAppDispatch } from "src/hooks/redux"
import { thunkFinishVerifyPhone } from "src/store"
import { parseError } from "src/utilities/errorUtils"
import { stripNonAlphaNumericCharacters } from "src/utilities"

const validationSchema = yup.object({
  verificationCode: yup
    .string()
    .transform(value => stripNonAlphaNumericCharacters(value))
    .matches(/^[0-9]+$/, "Must be only digits")
    .length(6, "The code should be six digits long")
    .required("Please provide the six-digit code"),
})
type FormData = yup.InferType<typeof validationSchema>

const PhoneVerify: React.FC = () => {
  const [error, setError] = useState("")
  const initialValues = { verificationCode: "" }
  const dispatch = useAppDispatch()

  const {
    onboardingFormData: { phoneNumber },
    proceedToNextScreen,
    setOnboardingScreen,
  } = useOnboardingContext()

  const handleSubmit = async (
    { verificationCode }: typeof initialValues,
    helpers: FormikHelpers<FormData>,
  ) => {
    try {
      const user = await dispatch(
        thunkFinishVerifyPhone({
          phoneNumber,
          verificationCode,
        }),
      )
      proceedToNextScreen({
        phoneVerified: user!.phoneVerified,
      })
    } catch (err) {
      const { errorMessage } = parseError(err)
      setError(errorMessage)
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Pages.FullPage>
      <Helmet>
        <title>Card Application | Stretch</title>
      </Helmet>
      <Panels.Default>
        <Headings.H3>Enter the code sent to your phone.</Headings.H3>
        <Spacing.Horizontal />
        <StretchForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            isValid,
            isSubmitting,
          }) => (
            <Form>
              <NumericInput
                name="verificationCode"
                inputMode="numeric"
                placeholder="_ _ _ _ _ _"
                value={values.verificationCode}
                error={
                  (touched.verificationCode && errors.verificationCode) || ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={6}
                $center
                autoFocus
                autoComplete="off"
                sx={{ marginBottom: "1rem" }}
              />
              <SubmitButton
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              />
              {!!error && <Feedback message={error} />}
            </Form>
          )}
        </StretchForm>
      </Panels.Default>
      <p>
        Didn’t receive the text message?{" "}
        <Button onClick={() => setOnboardingScreen("Phone Enter")} $secondary>
          Re-enter phone number
        </Button>
      </p>
    </Pages.FullPage>
  )
}

export default PhoneVerify
