/* eslint-disable no-unused-vars */

import { Address } from "./commonTypes"

export type AccountHolderStatusResponse = {
  success:boolean
  userRequestedAccountClosure:boolean
  financialAccountStatus: FinancialAccountStatus
  permanentCardStatus?: PaymentCardStatus
  temporaryCardStatus?: PaymentCardStatus
  debitAccountClosureDate?: Date
  error?: string
}

export type AccountHolderPersonalDetails = {
  address: Address
}

export type BankingOnboardingStatus =
  | "NONE"
  | "APP_SUBMITTED"
  | "APP_APPROVED"
  | "ACCOUNT_CREATED"
  | "PERM_CARD_ISSUED"

export type ClientToken = {
  value: string
  expiresAt: string
}

export type CardApplication = {
  status: CardApplicationStatus
  documentUploadSessionId?: string
  verificationTags?: VerificationTag[]
}

export type CardApplicationStatus =
  | "DENIED"
  | "APPROVED"
  | "PENDING"
  | "MANUAL_REVIEW"
  | "DOCUMENT_UPLOAD_REQUIRED"
  | "CLOSED"

export type VerificationTag =
  | "ADDRESS_MISMATCH"
  | "DOB_MISKEY"
  | "DOB_MISMATCH"
  | "NAME_MISMATCH"
  | "PHONE_MISMATCH"
  | "SSN_MISKEY"
  | "SSN_MISMATCH"
  | "MEDIUM_ABUSE_SCORE"
  | "HIGH_ABUSE_SCORE"
  | "MEDIUM_THEFT_SCORE"
  | "HIGH_THEFT_SCORE"

export type KycMismatch =
  | "ADDRESS"
  | "DOB"
  | "IDENTITY_THEFT"
  | "NAME"
  | "PHONE"
  | "SSN"
  | "SYNTHETIC_FRAUD"

export type ApplicationDocumentCategory = "PRIMARY" | "SECONDARY" | "SUPPORTING"
export const ApplicationDocuments = [
  "BIRTH_CERTIFICATE",
  "DRIVERS_LICENSE",
  "LEASE_AGREEMENT",
  "PASSPORT",
  "PAY_STUB",
  "PHONE_BILL",
  "PRISON_ID",
  "PRISON_RELEASE_PAPERWORK",
  "SOCIAL_SECURITY_CARD",
  "UTILITY_BILL",
  "NULL_DOC_TYPE",
] as const
export type ApplicationDocumentType = (typeof ApplicationDocuments)[number]

export type DocumentToUpload = {
  file: File
  type: ApplicationDocumentType
}

export type UploadRequirements = {
  primaryDocument: DocumentToUpload
  secondaryDocument: DocumentToUpload
}

export type FinancialAccountStatus =
  | "ACTIVE"
  | "SUSPENDED"
  | "PENDING_CLOSURE"
  | "CLOSED"

export type FinancialAccount = {
  status: FinancialAccountStatus
  availableCashBalance: number
  currencyCode: CurrencyCode
  directDepositDetailsId: string
  transactions?: Transaction[],
  transactionsLoaded?:boolean
}

export type FinancialAccountType = "CHECKING" | "SAVINGS"

export type PaymentCard = {
  id: string
  status: PaymentCardStatus
  formFactor: PaymentCardFormFactor
  lifecycle: PaymentCardLifecycle
  isExpiring: boolean
}

export type PaymentCardStatus =
  | "ACTIVATION_REQUIRED"
  | "ACTIVE"
  | "SUSPENDED"
  | "CLOSED"

export type PaymentCardFormFactor = "PHYSICAL" | "VIRTUAL"
export type PaymentCardLifecycle = "TEMPORARY" | "PERMANENT"
export type PaymentCardTokenPermission =
  | "READ_RESTRICTED_DETAILS"
  | "SET_PAYMENT_CARD_PIN"
export type PhysicalCardOrder = {
  status: PhysicalCardOrderStatus
  recipientName: string
  recipientAddress: Address
  shippingMethod: string
  signatureRequired: boolean
  trackingUrl?: string
  shipDate?: string
}

export type PhysicalCardOrderStatus =
  | "NEW"
  | "SENT_TO_PRINTER"
  | "APPROVED"
  | "CANCELED"
  | "SHIPPED"
  | "SHIP_FAILED"

export type Transaction = {
  id: string
  createdAt: string
  updatedAt?: string
  state: TransactionState
  amount: number
  type: TransactionType
  merchantName: string
  merchantCategory?: string
  currencyCode: CurrencyCode
  enrichedDescription?:string
  personalCategoryUrl?:string
  transactionLogoUrl?:string
  requestedAmount?: number,
  approvedAmount?: number,
  originalAmount?: number,
  primaryPersonalCategory?: string
  cardLastFour?: string
  enrichedMerchantName?: string
  derivedTransactionType?: string
}

export type TransactionType = "DEBIT" | "CREDIT" | "NEUTRAL"

export type TransactionState =
  | "PENDING"
  | "DECLINED"
  | "COMPLETE"
  | "REVERSED"
  | "FAILED"

export type CurrencyCode =
  | "AED"
  | "AFN"
  | "ALL"
  | "AMD"
  | "ANG"
  | "AOA"
  | "ARS"
  | "AUD"
  | "AWG"
  | "AZN"
  | "BAM"
  | "BBD"
  | "BDT"
  | "BGN"
  | "BHD"
  | "BIF"
  | "BMD"
  | "BND"
  | "BOB"
  | "BRL"
  | "BSD"
  | "BTN"
  | "BWP"
  | "BYR"
  | "BZD"
  | "CAD"
  | "CDF"
  | "CHF"
  | "CLP"
  | "CNY"
  | "COP"
  | "CRC"
  | "CUC"
  | "CUP"
  | "CVE"
  | "CZK"
  | "DJF"
  | "DKK"
  | "DOP"
  | "DZD"
  | "EGP"
  | "ERN"
  | "ETB"
  | "EUR"
  | "FJD"
  | "FKP"
  | "GBP"
  | "GEL"
  | "GHS"
  | "GIP"
  | "GMD"
  | "GNF"
  | "GTQ"
  | "GYD"
  | "HKD"
  | "HNL"
  | "HRK"
  | "HTG"
  | "HUF"
  | "IDR"
  | "ILS"
  | "INR"
  | "IQD"
  | "IRR"
  | "ISK"
  | "JMD"
  | "JOD"
  | "JPY"
  | "KES"
  | "KGS"
  | "KHR"
  | "KMF"
  | "KPW"
  | "KRW"
  | "KWD"
  | "KYD"
  | "KZT"
  | "LAK"
  | "LBP"
  | "LKR"
  | "LRD"
  | "LSL"
  | "LTL"
  | "LYD"
  | "MAD"
  | "MDL"
  | "MGA"
  | "MKD"
  | "MMK"
  | "MNT"
  | "MOP"
  | "MRO"
  | "MUR"
  | "MVR"
  | "MWK"
  | "MXN"
  | "MYR"
  | "MZN"
  | "NAD"
  | "NGN"
  | "NIO"
  | "NOK"
  | "NPR"
  | "NZD"
  | "OMR"
  | "PAB"
  | "PEN"
  | "PGK"
  | "PHP"
  | "PKR"
  | "PLN"
  | "PYG"
  | "QAR"
  | "RON"
  | "RSD"
  | "RUB"
  | "RWF"
  | "SAR"
  | "SBD"
  | "SCR"
  | "SDG"
  | "SEK"
  | "SGD"
  | "SHP"
  | "SLL"
  | "SOS"
  | "SRD"
  | "SSP"
  | "STD"
  | "SYP"
  | "SZL"
  | "THB"
  | "TJS"
  | "TMT"
  | "TND"
  | "TOP"
  | "TRY"
  | "TTD"
  | "TWD"
  | "TZS"
  | "UAH"
  | "UGX"
  | "USD"
  | "UYU"
  | "UZS"
  | "VEF"
  | "VND"
  | "VUV"
  | "WST"
  | "XAF"
  | "XCD"
  | "XOF"
  | "XPF"
  | "YER"
  | "ZAR"
  | "ZMW"
