//import { DateTime } from "luxon"
import React from "react"
//import theme from "src/assets/theme"
//import { Grid, GridItem } from "src/components"
//import { Grid } from '@mantine/core';
import { Button, Table } from '@mantine/core';
//import { Transaction, TransactionState } from "src/types"
import { Transaction, TransactionType } from "src/types"
//import { presentAmount } from "src/utilities"
//import styled, { css } from "styled-components"
//import { Image } from '@mantine/core';
//import { format,endOfDay,startOfDay,isBefore, isAfter, subDays } from "date-fns";
import { format } from "date-fns";
import { IconChevronRight } from '@tabler/icons';
//import {MantineModal } from "src/components"
import {useState} from "react"
import { Modal} from '@mantine/core';
import approveIcon from "src/assets/images/approve_icon.svg"
import pendingIcon from "src/assets/images/pending_icon.svg"
import rejectIcon from "src/assets/images/reject_icon.svg"


type TransactionListProps = {
  transactions: Transaction[]
}

const TransactionListV2: React.VFC<TransactionListProps> = ({ 
  transactions}) => {

    const [transactionDetailOpened, setTransactionDetailOpened] = useState(false);

    const defaultTransaction:Transaction = {
      id: '',
      createdAt: '2023-12-07T22:10:57.484Z',
      updatedAt: '2023-12-07T22:10:57.484Z',
      state: 'PENDING',
      amount: 0,
      type: 'NEUTRAL',
      merchantName: '',
      merchantCategory: '',
      currencyCode: 'USD',
      enrichedDescription:'',
      personalCategoryUrl:'',
      transactionLogoUrl:'',
      requestedAmount:0,
      approvedAmount:0,
      primaryPersonalCategory:'',
      cardLastFour:'',
      enrichedMerchantName:''
    }

    const [openedTransaction, setOpenedTransaction] = useState(defaultTransaction);

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const transactionDate = (transaction:Transaction) => {
      const date = new Date(transaction.createdAt);
      const formattedDate = format(date, "MMM. do");
      return formattedDate;
    }

    const transactionFullDate = (transaction:Transaction) => {
      const date = new Date(transaction.createdAt);
      const formattedDate = format(date, "MM/dd/yyyy");
      return formattedDate;
    }

    const TransactionLeftColumn = (transaction:Transaction) => {
      return(
          <>
              {transaction.transactionLogoUrl != null && (
                  <>
                      <img style={{width:"100%", height:"auto", maxHeight:"50px", maxWidth:"50px", minHeight:"25px", minWidth:"25px"}} src={transaction.transactionLogoUrl} alt="Transaction image" width="50" height="50"></img>
                  </>
              )}
  
              {transaction.transactionLogoUrl == null && transaction.state == "COMPLETE" && (
                  <>
                      <img src={approveIcon} alt="Transaction image" width="50" height="50"></img>
                  </>
              )}

              {transaction.transactionLogoUrl == null && transaction.state == "FAILED" && (
                  <>
                      <img src={rejectIcon} alt="Transaction image" width="50" height="50"></img>
                  </>
              )}

              {transaction.transactionLogoUrl == null && transaction.state == "DECLINED" && (
                  <>
                      <img src={rejectIcon} alt="Transaction image" width="50" height="50"></img>
                  </>
              )}

              {transaction.transactionLogoUrl == null && transaction.state == "PENDING" && (
                  <>
                      <img src={pendingIcon} alt="Transaction image" width="50" height="50"></img>
                  </>
              )}

              {transaction.transactionLogoUrl == null && transaction.state == "REVERSED" && (
                  <>
                      <img src={approveIcon} alt="Transaction image" width="50" height="50"></img>
                  </>
              )}
          </>   
      );
    }

    const formatAmount = (transaction:Transaction) => {

      let amount = transaction.amount;

      if(amount != undefined){
        amount = amount/100.0; //move decimal places over
      }else{
        amount = 0.0; //move decimal places over
      }
      
      const outcome = formatCurrency(amount, transaction.type);

      return outcome;
    }


    const formatRequestedAmount = (transaction:Transaction) => {

      let amount = transaction.requestedAmount;

      if(amount != undefined){
        amount = amount/100.0; //move decimal places over
      }else{
        amount = 0.0; //move decimal places over
      }
      
      const outcome = formatCurrency(amount, transaction.type);

      return outcome;
    }

    const formatApprovedAmount = (transaction:Transaction) => {


      let amount = transaction.approvedAmount;

      if(amount != undefined){
        amount = amount/100.0; //move decimal places over
      }else{
        amount = 0.0; //move decimal places over
      }
      
      const outcome = formatCurrency(amount, transaction.type);

      return outcome;

    }

    const formatReversalAmount = (transaction:Transaction) => {

      let amount = transaction.approvedAmount;

      if(amount != undefined){
        amount = amount/100.0; //move decimal places over
      }else{
        amount = 0.0; //move decimal places over
      }
      
      //Reversals should be positive
      const outcome = formatCurrency(amount, "CREDIT");

      return outcome;

    }

    const formatCurrency = (amount:number, type:TransactionType) => {

      //formmatted amount
      let formattedAmount = formatter.format(amount);

      if(amount != 0){ //only apply logic to non-zero amounts
        if(type === "DEBIT"){
          formattedAmount = "-" + formattedAmount
        }else if(type === "CREDIT"){
            formattedAmount = "+" + formattedAmount
        }
      }

      return formattedAmount;

    }

    /*
    beautifies personal category information from plaid.  Makes underscores spaces and Capitalizes text
    */
    const beautifyPersonalFinanceCategory = (text:any) => {
        if(text != undefined){
          text = text.replaceAll("_"," ");
          text = text.toLowerCase();
          text = capitalizeWords(text);
        }else {
          text = ''
        }

        return text;
    }


    const capitalizeWords = (str:string) => {
      return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const transactionStateToText = (transaction:Transaction) => {

      switch (transaction.state) {
        case "PENDING":
          return "Processing"
        case "COMPLETE":
          return "Complete"
        case "DECLINED":
          return "Declined"
        case "REVERSED":
          return "Reversed"
        case "FAILED":
          return "Failed"
        default: {
          return "Unknown"
        }
      }
    }

    const TransactionMiddleColumn = (transaction:Transaction) =>{
      return(
          <Table verticalSpacing="sm" fontSize="xl">
            <tr>
              <td style={{border: '0', fontSize:14, fontFamily:"Poppins", fontWeight:800}}><div style={{overflowWrap:'break-word', wordWrap:'break-word', wordBreak:'break-word'}}>{transaction.enrichedMerchantName}</div></td>
            </tr>
            <tr>
              <td style={{border: '0', fontSize:14, fontFamily:"Poppins", fontWeight:400}}>{transactionStateToText(transaction)}</td>
            </tr>
          </Table>
      );
    }

    const TransactionRightColumn = (transaction:Transaction) =>{
      return(
        <Table verticalSpacing="sm" fontSize="xl">
          <tr>
            <td style={{border: '0', minWidth:'100px', fontSize:14, fontFamily:"Poppins", fontWeight:400}}>
            {transaction.state === "DECLINED" &&( //Only strike through declined transactions
                <span style={{textDecoration: 'line-through'}}>

                    {formatAmount(transaction)}

                </span>
            )}
    
            {(transaction.state != "DECLINED" && transaction.derivedTransactionType != "REVERSAL") &&(

                formatAmount(transaction)

            )}

            {(transaction.derivedTransactionType === "REVERSAL") &&(

                formatReversalAmount(transaction)

            )}
            </td>
          </tr>
          <tr>
            <td style={{border: '0', fontSize:14, fontFamily:"Poppins", fontWeight:400}}>{transactionDate(transaction)}</td>
          </tr>
        </Table>
      );
    }


    const TransactionActionColumn = () => {
      return(
        <Table verticalSpacing="sm" fontSize="xl">
          <tr>
            <td style={{border: '0', minWidth:'2px'}}>
              <Button style={{color:'black', minWidth:5, backgroundColor:'transparent'}} rightIcon={<IconChevronRight size={40}></IconChevronRight>}>

              </Button>
            </td>
          </tr>
        </Table>
      );
    }

    const openTransactionDetail = (transaction:Transaction) => {
      console.log('Opening transaction detail')
      console.log(transaction)
      setOpenedTransaction(transaction)
      setTransactionDetailOpened(true)
    }
    
    const rows = transactions.map((element) => (
        <tr onClick={() => openTransactionDetail(element)} key={element.id} >
          <td style={{border: '0'}}>{TransactionLeftColumn(element)}</td>
          <td style={{border: '0'}}>{TransactionMiddleColumn(element)}</td>
          <td style={{border: '0', textAlign:'right', minWidth:"10px"}}>{TransactionRightColumn(element)}</td>
          <td style={{border: '0', textAlign:'right', minWidth:"5px"}}>{TransactionActionColumn()}</td>
        </tr>
      ));

      return (
        <Table style={{tableLayout:'fixed'}}>
          <thead >
            <tr>
              <th style={{width:"20%"}}></th>
              <th style={{width:"35%"}}></th>
              <th style={{width:"20%"}}></th>
              <th style={{width:"15%"}}></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
            <Modal
              opened = {transactionDetailOpened}
              onClose={() => setTransactionDetailOpened(false)}
              title="Transaction Detail"
              centered
            >

            <Table>
              <thead >
                <tr>
                  <th style={{width:"40%", border:'0'}}></th>
                  <th style={{width:"60%", border:'0'}}></th>
                </tr>
              </thead>
              <tbody>
                {(openedTransaction.cardLastFour != undefined && openedTransaction.cardLastFour != '') && (
                  <tr>
                    <td style={{border: '0'}}>Card Last Four</td>
                    <td style={{border: '0', textAlign:'right'}}>{openedTransaction.cardLastFour}</td>
                  </tr>
                )}
                <tr>
                  <td style={{border: '0'}}>Category</td>
                  <td style={{border: '0', textAlign:'right'}}>{beautifyPersonalFinanceCategory(openedTransaction.primaryPersonalCategory)}</td>
                </tr>
                <tr>
                  <td style={{border: '0'}}>Date</td>
                  <td style={{border: '0', textAlign:'right'}}>{transactionFullDate(openedTransaction)}</td>
                </tr>
                {(openedTransaction.state === 'DECLINED' && openedTransaction.derivedTransactionType != 'REVERSAL') && (
                <tr>
                  <td style={{border: '0'}}>Declined Amount</td>
                  <td style={{border: '0', textAlign:'right'}}>{formatRequestedAmount(openedTransaction)}</td>
                </tr> 
                )}
                {((openedTransaction.type === 'CREDIT' || openedTransaction.type === 'NEUTRAL') && openedTransaction.derivedTransactionType != 'REVERSAL') &&(
                  <tr>
                    <td style={{border: '0'}}>Amount</td>
                    <td style={{border: '0', textAlign:'right'}}>{formatAmount(openedTransaction)}</td>
                  </tr>
                )}
                {(openedTransaction.type === 'DEBIT' && openedTransaction.derivedTransactionType != 'REVERSAL') && (
                  <tr>
                    <td style={{border: '0'}}>Approved Amount</td>
                    <td style={{border: '0', textAlign:'right'}}>{formatApprovedAmount(openedTransaction)}</td>
                  </tr>
                )}
                {(openedTransaction.derivedTransactionType === 'REVERSAL') && (
                  <tr>
                    <td style={{border: '0'}}>Reversal Amount</td>
                    <td style={{border: '0', textAlign:'right'}}>{formatReversalAmount(openedTransaction)}</td>
                  </tr>
                )}
                <tr>
                  <td style={{border: '0'}}>Status</td>
                  <td style={{border: '0', textAlign:'right'}}>{transactionStateToText(openedTransaction)}</td>
                </tr>
              </tbody>

            </Table>
            </Modal>
        </Table>
      );
}

export default TransactionListV2
