import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit"
import {
  DirectDepositDetailsData,
  getDirectDepositDetails,
} from "src/api/highnote-api"
import {
  getDirectDepositDetailsToken,
  getFinancialAccount,
  issueFinancialAccount,
} from "src/api"
import { RootState } from "src/store/store"
import {
  AsyncThunkLoadingStatus,
  FinancialAccount,
  isStateLoading,
  stateStatus,
} from "src/types"
import { isApiAuthenticationError } from "src/utilities/errorUtils"
import { redirectAfterSessionExpired } from "src/utilities/routingUtils"
import { thunkClearUserData } from "../user/userReducer"
import { setTransactions } from "./transactions"


type State = {
  financialAccount?: FinancialAccount
  financialAccountLoadingStatus: AsyncThunkLoadingStatus
  directDepositDetails?: DirectDepositDetailsData
  directDepositLoadingStatus: AsyncThunkLoadingStatus
  financialTransactionsLoadingStatus: AsyncThunkLoadingStatus
}

const initialState: State = {
  financialAccountLoadingStatus: "idle",
  directDepositLoadingStatus: "idle",
  financialTransactionsLoadingStatus: "idle"
}

const slice = createSlice({
  name: "financialAccount",
  initialState,
  reducers: {
    setFinancialAccount: (state, action: PayloadAction<FinancialAccount>) => {
      state.financialAccount = action.payload
    },
    setDirectDepositDetails: (
      state,
      action: PayloadAction<DirectDepositDetailsData>,
    ) => {
      state.directDepositDetails = action.payload
    },
    setDirectDepositLoadingStatus: (
      state,
      action: PayloadAction<AsyncThunkLoadingStatus>,
    ) => {
      state.financialAccountLoadingStatus = action.payload
    },
    setFinancialTransactionsLoadingStatus: (
      state,
      action: PayloadAction<AsyncThunkLoadingStatus>,
    ) => {
      state.financialTransactionsLoadingStatus = action.payload
    },
  },
})

export const financialAccountSlice = slice.reducer

export const {
  setFinancialAccount,
  setDirectDepositDetails,
  setDirectDepositLoadingStatus,
  setFinancialTransactionsLoadingStatus,
} = slice.actions

export const thunkIssueFinancialAccount =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const financialAccount = await issueFinancialAccount()
      dispatch(setFinancialAccount(financialAccount))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const thunkRefreshFinancialAccount =
  (
    withTransactions = false,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {

    try {

      if(withTransactions){
        dispatch(setFinancialTransactionsLoadingStatus("started"))
      }
      
      const { transactions, ...financialAccount } = await getFinancialAccount(
        withTransactions,
      )

      if(withTransactions){
        financialAccount.transactionsLoaded = true;
      }

      dispatch(setFinancialAccount(financialAccount))

      if (withTransactions) {
        dispatch(setFinancialTransactionsLoadingStatus("complete"))
        if (!transactions) {
          
          throw new Error("transactions cannot be falsy")
        }
        //set transactions once they are loaded
        
        dispatch(setTransactions(transactions))

       

      }

    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const thunkGetDirectDepositDetails =
  (
    directDepositDetailsId: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    dispatch(setDirectDepositLoadingStatus("pending"))
    try {
      const { value } = await getDirectDepositDetailsToken()
      const details = await getDirectDepositDetails(
        directDepositDetailsId,
        value,
      )
      dispatch(setDirectDepositDetails(details))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    } finally {
      dispatch(setDirectDepositLoadingStatus("idle"))
    }
  }

  
export const selectFinancialAccount = (
  rootState: RootState,
): FinancialAccount | undefined => {
  return rootState.financialAccount.financialAccount
}

export const selectHasFinancialAccount = (rootState: RootState): boolean => {
  return rootState.financialAccount.financialAccount !== undefined
}

export const selectDirectDepositDetails = (
  rootState: RootState,
): DirectDepositDetailsData | undefined => {
  return rootState.financialAccount.directDepositDetails
}

export const selectAreDirectDepositDetailsLoading = (
  rootState: RootState,
): boolean => {
  return isStateLoading(rootState.financialAccount.directDepositLoadingStatus)
}

export const selectFinancialAccountDetailsLoading = (
  rootState:RootState,
): string => {
  return rootState.financialAccount.directDepositLoadingStatus;
}

export const selectAreFinancialAccountTransactionsLoading = (
  rootState: RootState,
): boolean => {
  return isStateLoading(rootState.financialAccount.financialTransactionsLoadingStatus)
}

export const selectFinancialAccountTransactionsStatus = (
  rootState: RootState,
): string => {
  return stateStatus(rootState.financialAccount.financialTransactionsLoadingStatus)
}
 

