import {
  CardViewerConfigError,
  renderFields,
} from "@highnoteplatform/card-viewer"
import styled from "styled-components"
import React, { useEffect, useRef, useState } from "react"
import { Loader, Space, Text, TextProps } from "@mantine/core"

import {
  Spacing,
  Message,
  Feedback,
  MantineDefaultButton as Button,
} from "src/components"
import theme from "src/assets/theme"
import { DEFAULT_ERROR_MESSAGE } from "src/utilities/errorUtils"
import cardBackground from "src/assets/images/virtual_card_background.svg"

const CARD_BACKGROUND_WIDTH_PX = 244
const CARD_BACKGROUND_HEIGHT_PX = 154

type CardImageProps = {
  cardHolderName: string
  clientToken: string
  paymentCardId: string
  isHighnoteProdEnv: boolean
}
const CardImage: React.VFC<CardImageProps> = ({
  clientToken,
  paymentCardId,
  cardHolderName,
  isHighnoteProdEnv,
}) => {
  const [loading, setLoading] = useState(true)
  const [maskOn, setMaskOn] = useState(true)
  const [error, setError] = useState("")
  const [copiedMessage, setCopiedMessage] = useState("")
  const toggleMaskFn = useRef(() => {})
  const cardNumberStyle = {
    color: theme.white,
    fontSize: "1rem",
    cursor: "pointer",
  }
  const otherFieldStyles = {
    color: theme.white,
    fontSize: "12px",
    fontWeight: "400",
    cursor: "pointer",
    lineHeight: "10px",
  }

  const onToggleClicked = () => {
    toggleMaskFn.current()
    setMaskOn(!maskOn)
  }

  const handleError = (error: CardViewerConfigError) => {
    console.error(error)
    setError(DEFAULT_ERROR_MESSAGE)
  }

  useEffect(() => {
    async function setupCardViewer() {
      setLoading(true)
      setError("")
      const renderedFields = await renderFields({
        clientToken,
        paymentCardId,
        enableClipboard: true,
        environment: isHighnoteProdEnv ? "live" : "test",

        onCopyToClipboardSuccess: ({ field }) => {
          let fieldName
          switch (field) {
            case "cardNumber":
              fieldName = "card number"
              break
            case "expirationDate":
              fieldName = "expiration date"
              break
            default:
              fieldName = "CVV"
              break
          }
          setCopiedMessage(`copied ${fieldName}!`)
        },
        onError: handleError,

        // Specify the individual fields to render data into
        elements: {
          cardNumber: {
            selector: "#cardNumber",
            styles: cardNumberStyle,
          },

          cvv: {
            selector: "#cvv",
            styles: otherFieldStyles,
          },

          expirationDate: {
            selector: "#expirationDate",
            styles: otherFieldStyles,
          },
        },
      })

      return renderedFields
    }

    let unmountFn: () => Promise<void>
    setupCardViewer().then(renderFields => {
      unmountFn = renderFields.unmount
      toggleMaskFn.current = renderFields.toggleCardNumberMask
      setLoading(false)
      setMaskOn(true)
    })

    return () => {
      unmountFn()
    }
  }, [clientToken, paymentCardId, isHighnoteProdEnv])

  return (
    <Container>
      {loading && <Loader color={theme.red} />}

      <CardWrapper hidden={loading}>
        <div className="cardRow first">
          <CardLabel transform="uppercase">Card Number</CardLabel>
          <div id="cardNumber" className="cardField">
            {/* An iframe will be injected here */}
          </div>
        </div>

        <div className="cardRow">
          <CardLabel transform="uppercase">Name</CardLabel>
          <CardName transform="uppercase">{cardHolderName}</CardName>
        </div>

        <div className="cardLastRow">
          <div>
            <CardLabel transform="uppercase" className="label">
              EXP
            </CardLabel>
            <div id="expirationDate" className="cardField">
              {/* An iframe will be injected here */}
            </div>
          </div>
          <div>
            <CardLabel transform="uppercase" className="label">
              CVV
            </CardLabel>
            <div id="cvv" className="cardField">
              {/* An iframe will be injected here */}
            </div>
          </div>
        </div>
      </CardWrapper>

      <Space h="md" />
      <Button label={maskOn ? "Reveal" : "Hide"} onClick={onToggleClicked} />
      {!!error && <Feedback message={error} />}
      {!!copiedMessage && (
        <Message
          message={copiedMessage}
          onTimeout={() => setCopiedMessage("")}
        />
      )}
      <Spacing.Horizontal />
      <Text size="xs" italic>
        This card is issued by Sutton Bank, Member FDIC, pursuant to license by
        Mastercard International.
      </Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const CardLabel = styled(Text)<TextProps>`
  color: ${({ theme }) => theme.white};
  padding: 0 0 0 4px;
  font-family: ${({ theme }) => theme.mono};
  font-size: 7px;
  font-weight: 400;
`

const CardName = styled(CardLabel)<TextProps>`
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
`

const CardWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  background-image: url(${cardBackground});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 16px;
  height: ${CARD_BACKGROUND_HEIGHT_PX}px;
  width: ${CARD_BACKGROUND_WIDTH_PX}px;
  background-color: #f9f6ef;
  .cardRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 14%;
    width: 80%;
    padding-left: 8%;
    overflow: hidden;
  }
  .cardRow.first {
    height: 19%;
    margin-bottom: 12px;
  }
  .cardLastRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 63%;
    height: 8%;
    padding-left: 8%;
  }
  .cardLastRow .label {
    margin-top: 6px;
  }
  .cardLastRow > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 18px;
    overflow: hidden;
    align-items: flex-start;
    gap: 0px;
  }
  .cardField > iframe {
    width: 100%;
  }
  .cardField #field {
    font-size: 10px;
    font-weight: 600;
  }
`

export default CardImage
