import { Form } from "formik"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import * as yup from "yup"
import "react-phone-number-input/style.css"
import {
  Bucket,
  Button,
  Pages,
  Feedback,
  Headings,
  Panels,
  Spacing,
  StretchForm,
  PhoneNumberInput,
} from "src/components"
import { useOnboardingContext } from "src/context/OnboardingContext"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import { selectLoggedInUser, thunkUpdateUserAccount } from "src/store"
import { parseError } from "src/utilities/errorUtils"
import { didValuesChange } from "src/utilities/formUtils"
import Disclaimer from "./Disclaimer"

const validationSchema = yup.object({
  phoneNumber: yup
    .string()
    .label("Phone Number")
    .required("Please provide a phone number")
    // The PhoneInput component adds 2 additional characters to the value "+1"
    // so the validated length should be 12 instead of 10
    .length(12, "Make sure your phone number is 10 digits"),
})

const Phone: React.FC = () => {
  const { phoneVerified } = useAppSelector(selectLoggedInUser)
  const dispatch = useAppDispatch()
  const [error, setError] = useState("")
  const {
    goBackToPreviousScreen,
    proceedToNextScreen,
    onboardingFormData,
    isInReview,
  } = useOnboardingContext()
  const initialValues = { phoneNumber: onboardingFormData.phoneNumber }

  const handleSubmit = async (params: typeof initialValues) => {
    if (!didValuesChange(initialValues, params) && phoneVerified === true) {
      proceedToNextScreen({})
      return
    }
    try {
      await dispatch(thunkUpdateUserAccount(params))
      proceedToNextScreen({
        phoneNumber: params.phoneNumber,
        phoneVerified: false,
      })
    } catch (err) {
      const { errorMessage } = parseError(err)
      setError(errorMessage)
    }
  }

  return (
    <Pages.FullPage>
      <Helmet>
        <title>Card Application | Stretch</title>
      </Helmet>
      <Panels.Default>
        <StretchForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            values,
            handleBlur,
            setFieldValue,
            isValid,
            isSubmitting,
            touched,
          }) => (
            <>
              <Form>
                <div>
                  <Headings.H3>
                    What&apos;s your mobile phone number?
                  </Headings.H3>
                  <Spacing.Horizontal />
                  <div className="my-2">
                    <PhoneNumberInput
                      name="phoneNumber"
                      placeholder="Enter your phone number"
                      value={values.phoneNumber}
                      error={(touched.phoneNumber && errors.phoneNumber) || ""}
                      onChange={(value: string) => {
                        setFieldValue("phoneNumber", value || "")
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <Bucket $spaceM>
                  {!isInReview && (
                    <Button
                      onClick={() => goBackToPreviousScreen()}
                      type="button"
                      $secondary
                      $growAlt
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                    $growAlt
                  >
                    Continue
                  </Button>
                </Bucket>
                {!!error && <Feedback message={error} />}
              </Form>
            </>
          )}
        </StretchForm>
      </Panels.Default>
      <Spacing.Horizontal />
      <Disclaimer />
    </Pages.FullPage>
  )
}

export default Phone
