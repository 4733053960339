import { Grid } from "@mantine/core"
import React, { useEffect, useMemo, useState } from "react"
import Select from "./SelectV2"

type YearMonthDayDatePickerProps = {
  name: string
  minDate: Date
  maxDate: Date
  openToDate?: Date
  onDateSelected: (_fieldname: string, _value: Date) => void
  handleBlur: (_field: string) => void
}
const yearOptions = (minYear: number, maxYear: number) => {
  const years: number[] = []
  for (let index = minYear; index <= maxYear; index++) {
    years.push(index)
  }
  return [...years].map((value: number) => {
    const label = String(value)
    return {
      label,
      value: label,
    }
  })
}

const monthOptions = [
  { label: "January", value: "0" },
  { label: "February", value: "1" },
  { label: "March", value: "2" },
  { label: "April", value: "3" },
  { label: "May", value: "4" },
  { label: "June", value: "5" },
  { label: "July", value: "6" },
  { label: "August", value: "7" },
  { label: "September", value: "8" },
  { label: "October", value: "9" },
  { label: "November", value: "10" },
  { label: "December", value: "11" },
]

const dayOptions = (month: number) => {
  const thirtyDays = [3, 5, 8, 10]
  const days: number[] = []
  const lastDay = month === 1 ? 28 : thirtyDays.includes(month) ? 30 : 31
  for (let index = 1; index <= lastDay; index++) {
    days.push(index)
  }
  return [...days].map((value: number) => {
    const label = String(value)
    return {
      label,
      value: label,
    }
  })
}

const YearMonthDayDatePicker: React.VFC<YearMonthDayDatePickerProps> = ({
  name,
  minDate,
  maxDate,
  openToDate,
  onDateSelected,
  handleBlur,
}) => {
  const initialDate = openToDate ?? new Date()
  const [day, setDay] = useState(initialDate.getDate())
  const [month, setMonth] = useState(initialDate.getMonth())
  const [year, setYear] = useState(initialDate.getFullYear())
  const [minYear, maxYear] = [minDate.getFullYear(), maxDate.getFullYear()]
  const years = useMemo(() => yearOptions(minYear, maxYear), [minYear, maxYear])
  const months = useMemo(() => monthOptions, [])
  const days = useMemo(() => dayOptions(month), [month])

  const handleSelect = () => {
    onDateSelected(name, new Date(year, month, day))
  }

  useEffect(() => {
    handleSelect()
  }, [year, month, day])

  return (
    <Grid sx={{ width: "100%" }} columns={14} gutter="xs">
      <Grid.Col xs={4}>
        <Select
          name="year"
          data={years}
          value={year}
          onChange={event => {
            setYear(Number(event.target.value))
          }}
          onBlur={() => handleBlur(name)}
        />
      </Grid.Col>
      <Grid.Col xs={6}>
        <Select
          name="month"
          data={months}
          value={month}
          onChange={event => {
            setMonth(Number(event.target.value))
          }}
          onBlur={() => handleBlur(name)}
        />
      </Grid.Col>
      <Grid.Col xs={4}>
        <Select
          name="day"
          data={days}
          value={day}
          onChange={event => {
            setDay(Number(event.target.value))
          }}
          onBlur={() => handleBlur(name)}
        />
      </Grid.Col>
    </Grid>
  )
}

export default YearMonthDayDatePicker
