import { Form, FormikHelpers } from "formik"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import * as yup from "yup"
import { useDisclosure } from "@mantine/hooks"
import {
  Bucket,
  Button,
  Pages,
  Feedback,
  Headings,
  Panels,
  Spacing,
  StretchForm,
} from "src/components"
import { useOnboardingContext } from "src/context/OnboardingContext"
import { useAppDispatch } from "src/hooks/redux"
import { thunkUpdateUserAccount } from "src/store"
import { parseError } from "src/utilities/errorUtils"
import { didValuesChange } from "src/utilities/formUtils"
import Disclaimer from "./Disclaimer"
import SsnInput from "src/components/shared/inputs/text/SsnInput"
import { stripNonAlphaNumericCharacters } from "src/utilities"

const validationSchema = yup.object({
  ssn: yup
    .string()
    .transform(value => stripNonAlphaNumericCharacters(value))
    .matches(/^[0-9]+$/, "Must be only digits")
    .length(9, "Your SSN should be 9 digits long")
    .required("Please provide your SSN"),
})

const SSN: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    goBackToPreviousScreen,
    proceedToNextScreen,
    onboardingFormData,
    isInReview,
  } = useOnboardingContext()
  const [error, setError] = useState("")
  const initialValues = { ssn: onboardingFormData.ssn }

  type FormData = typeof initialValues
  const handleSubmit = async (
    params: FormData,
    { setSubmitting }: FormikHelpers<FormData>,
  ) => {
    if (!didValuesChange(initialValues, params)) {
      proceedToNextScreen({})
      return
    }
    try {
      // Make sure SSN is formatted as ###-##-####
      if (!params.ssn.includes("-")) {
        params.ssn = `${params.ssn.slice(0, 3)}-${params.ssn.slice(
          3,
          5,
        )}-${params.ssn.slice(5, 9)}`
      }
      await dispatch(thunkUpdateUserAccount(params))
      proceedToNextScreen(params)
    } catch (err) {
      const { errorMessage } = parseError(err)
      setError(errorMessage)
    }
    setSubmitting(false)
  }

  const [visible, { toggle }] = useDisclosure(false)

  return (
    <Pages.FullPage2>
      <Helmet>
        <title>Card Application | Stretch</title>
      </Helmet>
      <Panels.Default>
        <StretchForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            isValid,
            isSubmitting,
          }) => {
            return (
              <Form>
                <Headings.H3>
                  What&apos;s your Social Security Number?
                </Headings.H3>
                <Spacing.Horizontal />
                <SsnInput
                  placeholder="Enter your SSN"
                  name="ssn"
                  value={values.ssn}
                  error={(touched.ssn && errors.ssn) || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  visible={visible}
                  onVisibilityChange={toggle}
                />
                <Spacing.Horizontal />
                <Bucket $spaceM>
                  {!isInReview && (
                    <Button
                      onClick={() => goBackToPreviousScreen()}
                      type="button"
                      $secondary
                      $growAlt
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                    $growAlt
                  >
                    Continue
                  </Button>
                </Bucket>
                {!!error && <Feedback message={error} />}
              </Form>
            )
          }}
        </StretchForm>
      </Panels.Default>
      <Spacing.Horizontal />
      <Disclaimer />
    </Pages.FullPage2>
  )
}

export default SSN
