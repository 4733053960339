import { Form } from "formik"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import * as yup from "yup"
import { DateTime } from "luxon"
import {
  Bucket,
  Button,
  Pages,
  Feedback,
  Headings,
  Panels,
  Spacing,
  StretchForm,
  YearMonthDayDatePicker,
} from "src/components"
import { useOnboardingContext } from "src/context/OnboardingContext"
import { useAppDispatch } from "src/hooks/redux"
import { thunkUpdateUserAccount } from "src/store"
import { parseError } from "src/utilities/errorUtils"
import { didValuesChange } from "src/utilities/formUtils"
import Disclaimer from "./Disclaimer"

const validationSchema = yup.object({
  dateOfBirth: yup
    .date()
    .min(new Date(1900, 1), "Please enter a valid birth year")
    .max(new Date(), "Date of birth cannot be after today")
    .required("Please enter a valid date of birth"),
})

const DOB: React.FC = () => {
  const dispatch = useAppDispatch()
  const [error, setError] = useState("")
  const {
    goBackToPreviousScreen,
    proceedToNextScreen,
    onboardingFormData,
    isInReview,
  } = useOnboardingContext()

  const initialValues = {
    dateOfBirth: onboardingFormData.dob
      ? new Date(onboardingFormData.dob)
      : null,
  }
  const handleSubmit = async (params: typeof initialValues) => {
    if (!didValuesChange(initialValues, params)) {
      proceedToNextScreen({})
      return
    }
    const dobStringParams = {
      dob: params.dateOfBirth
        ? DateTime.fromJSDate(params.dateOfBirth).toFormat("yyyy-MM-dd")
        : "",
    }
    try {
      await dispatch(thunkUpdateUserAccount(dobStringParams))
      proceedToNextScreen(dobStringParams)
    } catch (err) {
      const { errorMessage } = parseError(err)
      setError(errorMessage)
    }
  }

  const validStartDateTime = DateTime.now().minus({ year: 18 })

  return (
    <Pages.FullPage>
      <Helmet>
        <title>Stretch - Onboarding</title>
      </Helmet>
      <Panels.Default>
        <StretchForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur
        >
          {({ values, isValid, isSubmitting, setFieldValue, handleBlur }) => {
            return (
              <Form>
                <div className="mb-6">
                  <Headings.H3>What&apos;s your birthday?</Headings.H3>
                  <Spacing.Horizontal />
                  <Bucket $spreadH>
                    <YearMonthDayDatePicker
                      name="dateOfBirth"
                      openToDate={
                        values.dateOfBirth ?? validStartDateTime.toJSDate()
                      }
                      minDate={new Date(1900, 1, 1)}
                      maxDate={validStartDateTime.toJSDate()}
                      onDateSelected={setFieldValue}
                      handleBlur={handleBlur}
                    />
                  </Bucket>
                </div>
                <Bucket $spaceM>
                  {!isInReview && (
                    <Button
                      onClick={() => goBackToPreviousScreen()}
                      type="button"
                      $secondary
                      $growAlt
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                    $growAlt
                  >
                    Continue
                  </Button>
                </Bucket>
                {!!error && <Feedback message={error} />}
              </Form>
            )
          }}
        </StretchForm>
      </Panels.Default>
      <Spacing.Horizontal />
      <Disclaimer />
    </Pages.FullPage>
  )
}

export default DOB
