import React from "react"
import { Button, Card } from "src/components"
import { useOnboardingContext } from "src/context/OnboardingContext"
import { BANKING_ONBOARDING_START_PAGE_ROUTE } from "src/utilities/routingUtils"

const NonBankingUser: React.VFC = () => {
  const { isOnboardingInProgress } = useOnboardingContext()
  return (
    <Card>
      <p className="font-italic">
        Get banking services and a Stretch Mastercard®.
      </p>
      <div className="mb-4"></div>
      <Button as="Link" to={BANKING_ONBOARDING_START_PAGE_ROUTE}>
        {!isOnboardingInProgress ? "Apply Now" : "Continue Application"}
      </Button>
    </Card>
  )
}

export default NonBankingUser
