import React from "react"
import { Helmet } from "react-helmet-async"
import { useOnboardingContext } from "src/context/OnboardingContext"
import { Button, Headings, Pages, Panels, Spacing } from "src/components"
import Disclaimer from "./Disclaimer"
import AddressForm from "src/components/form/AddressForm"

const AddressScreen: React.FC = () => {
  const {
    goBackToPreviousScreen,
    proceedToNextScreen,
    onboardingFormData,
    isInReview,
  } = useOnboardingContext()

  const initialValues = {
    firstLine: onboardingFormData.firstLine,
    secondLine: onboardingFormData.secondLine,
    locality: onboardingFormData.locality,
    region: onboardingFormData.region,
    postalCode: onboardingFormData.postalCode,
    country: onboardingFormData.country,
  }

  return (
    <Pages.FullPage2>
      <Helmet>
        <title>Card Application | Stretch</title>
      </Helmet>
      <Panels.Default>
        <Headings.H3>What&apos;s your address?</Headings.H3>
        <Spacing.Horizontal />
        <AddressForm
          disableSubmitUntilEditIsMade={!isInReview}
          initialAddress={initialValues}
          onSuccess={proceedToNextScreen}
          otherButtons={
            !isInReview && (
              <Button
                onClick={() => goBackToPreviousScreen()}
                type="button"
                $secondary
                $growAlt
              >
                Back
              </Button>
            )
          }
          submitButtonLabel="Continue"
        />
      </Panels.Default>
      <Spacing.Horizontal />
      <Disclaimer />
    </Pages.FullPage2>
  )
}

export default AddressScreen
