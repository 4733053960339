import React, { createContext, useContext, useEffect, useState } from "react"
import { useAppSelector } from "src/hooks/redux"
import { selectMaybeLoggedInUser } from "src/store"
import { ServerUser } from "src/types"
import { Address } from "src/types/commonTypes"

export type OnboardingFormData = {
  firstName: string
  lastName: string
  phoneNumber: string
  phoneVerified: boolean
  isPasscodeSet: boolean
  dob: string
  ssn: string
  activationCode: string
} & Address

export type OnboardingScreenName =
  | "Name"
  | "Phone Enter"
  | "Phone Verify"
  | "Passcode"
  | "DateOfBirth"
  | "SocialSecurityNumber"
  | "Address"
  | "Review"

const onboardingScreenSequence: OnboardingScreenName[] = [
  "Name",
  "Phone Enter",
  "DateOfBirth",
  "SocialSecurityNumber",
  "Address",
  "Review",
]

type OnboardingContextState = {
  isOnboardingInProgress: boolean
  isInReview: boolean
  setApplicationInReview: (_value: boolean) => void
  currentOnboardingScreen: OnboardingScreenName
  proceedToNextScreen: (_formData: Partial<OnboardingFormData>) => void
  goBackToPreviousScreen: () => void
  onboardingFormData: OnboardingFormData
  setOnboardingScreen: (_screenName: OnboardingScreenName) => void
}

const OnboardingContext = createContext<OnboardingContextState | undefined>(
  undefined,
)

function determineOnboardingScreen(
  formData: OnboardingFormData,
): OnboardingScreenName {
  let screenName: OnboardingScreenName
  if (!formData.isPasscodeSet) {
    screenName = "Passcode"
  } else if (!formData.firstName || !formData.lastName) {
    screenName = "Name"
  } else if (!formData.phoneNumber) {
    screenName = "Phone Enter"
  } else if (!formData.phoneVerified) {
    screenName = "Phone Verify"
  } else if (!formData.dob) {
    screenName = "DateOfBirth"
  } else if (!formData.ssn) {
    screenName = "SocialSecurityNumber"
  } else if (!formData.firstLine) {
    screenName = "Address"
  } else {
    screenName = "Review"
  }

  return screenName
}

function determineFormData(user?: ServerUser): OnboardingFormData {
  const {
    firstName,
    lastName,
    phone,
    phoneVerified,
    physicalAddress,
    bornOn,
    needsPasscodeAuth,
  } = user ?? {}
  const { firstLine, secondLine, locality, region, postalCode, country } =
    physicalAddress ?? {}
  return {
    firstName: firstName ?? "",
    lastName: lastName ?? "",
    phoneNumber: phone ?? "",
    phoneVerified: phoneVerified ?? false,
    isPasscodeSet: needsPasscodeAuth === true,
    firstLine: firstLine ?? "",
    secondLine: secondLine ?? "",
    locality: locality ?? "",
    region: region ?? "",
    postalCode: postalCode ?? "",
    country: country ?? "",
    dob: bornOn ?? "",
    ssn: "",
    activationCode: "",
  }
}

export const OnboardingProvider: React.FC = ({ children }) => {
  const currentStretchUser: ServerUser | undefined = useAppSelector(
    selectMaybeLoggedInUser,
  )

  const [onboardingFormData, setOnboardingFormData] =
    useState<OnboardingFormData>(determineFormData(currentStretchUser))

  useEffect(() => {
    setOnboardingFormData(determineFormData(currentStretchUser))
  }, [currentStretchUser])

  const [currentOnboardingScreen, setCurrentOnboardingScreen] = useState(
    determineOnboardingScreen(onboardingFormData),
  )
  useEffect(() => {
    setCurrentOnboardingScreen(determineOnboardingScreen(onboardingFormData))
  }, [onboardingFormData])
  const [isInReview, setIsInReview] = useState(false)

  const proceedToNextScreen = (newFormData: Partial<OnboardingFormData>) => {
    const updatedData = { ...onboardingFormData, ...newFormData }
    setOnboardingFormData(updatedData)
  }

  const goBackToPreviousScreen = () => {
    const previousPosition =
      onboardingScreenSequence.indexOf(currentOnboardingScreen) - 1

    if (previousPosition >= 0) {
      setCurrentOnboardingScreen(onboardingScreenSequence[previousPosition])
    }
  }

  const isOnboardingInProgress = !!onboardingFormData.firstName

  const setOnboardingScreen = (screenName: OnboardingScreenName) => {
    setCurrentOnboardingScreen(screenName)
  }

  const setApplicationInReview = (value: boolean) => {
    setIsInReview(value)
  }

  return (
    <OnboardingContext.Provider
      value={{
        isOnboardingInProgress,
        isInReview,
        setApplicationInReview,
        currentOnboardingScreen,
        onboardingFormData,
        proceedToNextScreen,
        goBackToPreviousScreen,
        setOnboardingScreen,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}

export const useOnboardingContext = (): OnboardingContextState => {
  const context = useContext(OnboardingContext)

  if (context === undefined) {
    throw new Error("OnboardingContext must be used with a Provider")
  }

  return context
}
