import {
  AnyAction,
  createSelector,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit"
import {
  applyForDebitCard,
  createBankingUserFromToken,
  getCardApplication,
  getCreateAccountHolderToken,
} from "src/api"
import {
  tokenizeUSPersonAccountHolder,
  USPersonAccountHolderParams,
} from "src/api/highnote-api"
import {
  RootState,
  thunkClearUserData,
  thunkRefreshUserSession,
} from "src/store"
import {
  AsyncThunkLoadingStatus,
  CardApplication,
  isStateLoading,
} from "src/types"
import {
  DEFAULT_ERROR_MESSAGE,
  isApiAuthenticationError,
} from "src/utilities/errorUtils"
import { redirectAfterSessionExpired } from "src/utilities/routingUtils"

type State = {
  cardApplication?: CardApplication
  loadingStatus: AsyncThunkLoadingStatus
}
const initialState: State = {
  loadingStatus: "idle",
}

export const slice = createSlice({
  name: "cardApplication",
  initialState,
  reducers: {
    setCardApplication: (state, action: PayloadAction<CardApplication>) => {
      state.cardApplication = action.payload
    },
    setCardApplicationLoading: (
      state,
      action: PayloadAction<AsyncThunkLoadingStatus>,
    ) => {
      state.loadingStatus = action.payload
    },
  },
})

export const cardApplicationSlice = slice.reducer

export const { setCardApplication, setCardApplicationLoading } = slice.actions

export const thunkApplyForBanking =
  (
    personAccountHolder: USPersonAccountHolderParams,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    const { value: clientAuthToken } = await getCreateAccountHolderToken()
    const personToken = await tokenizeUSPersonAccountHolder(
      personAccountHolder,
      clientAuthToken,
    )
    if (!personToken || !personToken.token || personToken.errors) {
      console.error(
        `Failed to tokenize person account holder. ${JSON.stringify(
          personToken?.errors,
        )}`,
      )
      throw new Error(DEFAULT_ERROR_MESSAGE)
    }

    try {
      dispatch(setCardApplicationLoading("pending"))
      await createBankingUserFromToken(personToken.token)
      const cardApplication = await applyForDebitCard()
      dispatch(setCardApplication(cardApplication))
      dispatch(thunkRefreshUserSession())
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }

      throw err
    } finally {
      dispatch(setCardApplicationLoading("idle"))
    }
  }

export const thunkRefreshCardApplication =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      dispatch(setCardApplicationLoading("pending"))
      const cardApplication = await getCardApplication()
      dispatch(setCardApplication(cardApplication))
    } catch (error) {
      if (isApiAuthenticationError(error)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }

      throw error
    } finally {
      dispatch(setCardApplicationLoading("idle"))
    }
  }

export const selectCardApplicationState = (rootState: RootState): State => {
  return rootState.cardApplication
}

export const selectIsCardApplicationStateLoading: (
  _rootState: RootState,
) => boolean = createSelector(selectCardApplicationState, state => {
  return isStateLoading(state.loadingStatus)
})

export const selectCardApplication: (
  _rootState: RootState,
) => CardApplication | undefined = createSelector(
  selectCardApplicationState,
  state => {
    return state.cardApplication
  },
)

export const selectHasApplied: (_rootState: RootState) => boolean =
  createSelector(selectCardApplication, cardApplication => {
    return cardApplication !== undefined
  })

export const selectApplicationApproved: (_rootState: RootState) => boolean =
  createSelector(selectCardApplication, cardApplication => {
    return cardApplication?.status === "APPROVED"
  })
