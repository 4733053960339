import { TextInput as MantineTextInput, TextInputProps } from "@mantine/core"
//import { NumberInput, NumberInputProps } from "@mantine/core"
import React, { forwardRef, InputHTMLAttributes } from "react"
import theme from "src/assets/theme"

export type Props = {
  $center?: boolean
  autoComplete?: string
  error?: string
  label?: string | React.ReactNode
} & InputHTMLAttributes<HTMLInputElement> &
  TextInputProps

const NumericInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      $center,
      autoComplete,
      placeholder,
      error,
      label,
      onBlur,
      onChange,
      name,
      value,
      size: _size,
      ...props
    },
    ref,
  ) => {
    return (
      <MantineTextInput
        name={name}
        autoComplete={autoComplete ?? name}
        placeholder={placeholder}
        value={value}
        error={error}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        styles={{
          label: {
            fontWeight: 600,
            marginBottom: "0.25rem",
            color: theme.grayT,
            fontSize: "0.875rem",
          },
          input: {
            textAlign: $center ? "center" : "left",
            padding: "0.75rem 2.75rem 0.75rem 1.25rem",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            height: "auto",
            borderColor: error ? theme.goldTT : "lightgray",
          },
          root: {
            marginBottom: "1rem",
          },
        }}
        {...props}
      />
    )
  },
)
NumericInput.displayName = "NumericInput"

export default NumericInput
