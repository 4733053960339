import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"

import { Feedback, Spinner } from "src/components"
// import { Horizontal } from "src/components/shared/layouts/Flex/FlexParent"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import {
  selectApplicationApproved,
  selectCardApplication,
  selectFinancialAccount,
  selectHasApplied,
  selectIsCardApplicationStateLoading,
  thunkIssueFinancialAccount,
} from "src/store"
import { parseError } from "src/utilities/errorUtils"
import ApplicationStatus from "./ApplicationStatus"
//import FullyOnboarded from "./FullyOnboarded"
import FullyOnboardedV2 from "./FullyOnboardedV2"
import NonBankingUser from "./NonBankingUser"
import ClosedFinancialAccountUser from "./ClosedFinancialAccountUser"
import { getAccountHolderStatusInfo } from "src/api"
import { Banner,Icon} from "src/components"

const DashboardV2: React.VFC = () => {
  const dispatch = useAppDispatch()
  const cardApplication = useAppSelector(selectCardApplication)
  const isCardApplicationLoading = useAppSelector(
    selectIsCardApplicationStateLoading,
  )

  const financialAccount = useAppSelector(selectFinancialAccount)
  const hasApplied = useAppSelector(selectHasApplied)
  const applicationApproved = useAppSelector(selectApplicationApproved)

  const [error, setError] = useState("")
  const [isLoading, setLoading] = useState(true)

  const [displayClosureRequestedBanner, setDisplayClosureRequestedBanner] = useState(false);
  // TODO: Create a separate ApplicationStatus page to handle pending application state
  // and create financial account once application is approved
  // TODO: Consider moving financial account creation to server-side and use HN events
  useEffect(() => {
    async function issueFinancialAccount() {
      try {
        await dispatch(thunkIssueFinancialAccount())
      } catch (err) {
        const { errorMessage } = parseError(err)
        setError(errorMessage)
      }
    }

    if (applicationApproved) {
      issueFinancialAccount()
    }

    getAccountHolderStatuses();
  }, [dispatch, applicationApproved])

  useEffect(() => {
    setLoading(isCardApplicationLoading)
  }, [isCardApplicationLoading])

 function getAccountHolderStatuses(){

  getAccountHolderStatusInfo().then(res => {

    if(res?.success == true){

      if(res?.userRequestedAccountClosure == true){

        if(!(financialAccount?.status == 'CLOSED')){

          setDisplayClosureRequestedBanner(true);

        }else{

          setDisplayClosureRequestedBanner(false);

        }
        
      }else{

        setDisplayClosureRequestedBanner(false);

      }
      
    }else {
      setDisplayClosureRequestedBanner(false);
      
    }

  })

}

  const determineUserExperience = () => {
    if (isLoading) {
      return <Spinner />
    }

    if(financialAccount?.status == 'CLOSED'){
      return <ClosedFinancialAccountUser />
    }else if(!hasApplied){
      return <NonBankingUser />
    } else {
      return applicationApproved ? (
        <FullyOnboardedV2 />
      ) : cardApplication ? (
        <ApplicationStatus cardApplication={cardApplication} />
      ) : null
    }

  }

  return (
    <>
      <Helmet>
        <title>Dashboard – Stretch</title>
      </Helmet>

      {!!error && <Feedback message={error} />}

      {displayClosureRequestedBanner && (
        <Banner>
          <Icon id="info" />
          We have received the request to close your account
        </Banner>
      )}

      {determineUserExperience()}
    </>
  )
}

export default DashboardV2
