import React from "react"
import { MaskedPasswordInput, MaskedPasswordInputProps } from "src/components"

type Props = Omit<
  MaskedPasswordInputProps,
  "mask" | "autoComplete" | "inputMode" | "centered"
>

const SsnInput: React.VFC<Props> = props => {
  /**
   * Use a 9-digit mask when input value is not visible to improve UX
   * and reduce confusion when entering the 3rd, 5th, & 7th digits and
   * two "*" would appear instead of just one.  Use the 11-digit mask
   * when input value is visible for easier visual verification.
   */
  return (
    <MaskedPasswordInput
      mask={props.visible ? "999-99-9999" : "999999999"}
      autoComplete="off"
      inputMode="numeric"
      letterSpacingSize="xs"
      centered
      {...props}
    />
  )
}

export default SsnInput
