import { Text } from "@mantine/core"
import React from "react"

const Disclaimer: React.VFC = () => {
  return (
    <Text size="xs" italic>
      To help the government fight the funding of terrorism and money laundering
      activities, Federal law requires all financial institutions to obtain,
      verify, and record information that identifies each person who opens an
      account.
    </Text>
  )
}

export default Disclaimer
